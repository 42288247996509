/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style";

// Replace above style with this SCSS file to enable dark mode styles
//@import "./assets/sass/style.dark";

@import "./assets/sass/style.angular.scss";

// Keenicons - High quality and pixel perfect font icons available in 3 styles, duotone, outline and solid for Metronic elements
@import "./assets/plugins/keenicons/duotone/style.css";
@import "./assets/plugins/keenicons/outline/style.css";
@import "./assets/plugins/keenicons/solid/style.css";

// Patches
html.cdk-global-scrollblock {
  position: initial !important;
  z-index: 1100 !important;
}

html.cdk-global-scrollblock body {
  position: fixed;
  z-index: 1100 !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  z-index: 1100 !important;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-dragging {
  position: fixed;
  z-index: 1100 !important;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.step-modal-wrapper {
  max-width: 65% !important;

  .modal-content {
    padding: 18px;
  }
}

.btn-web,
.btn-phone,
.btn-email {
  border: 1px solid #A6ABAD !important;
  height: 35px !important;
  width: 35px !important;
  border-radius: 10px;

  &:hover {
    background: #A6ABAD;
    color: white;
    fill: white;
  }
}

.btn-edit {
  border: 1px solid #0A3400 !important;
  color: #0A3400;

  &:hover {
    background-color: #0A3400;
    color: #F0EDD5;
  }
}

.warning-message {
  background-color: #FBEEE8;
  color: #DD2525;
  padding: 12px;
  border-radius: 10px;
}

mat-button-toggle-group {
  mat-button-toggle:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  mat-button-toggle:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  mat-button-toggle.mat-button-toggle-checked {
    font-weight: bold;
  }
}

/* MultiSelector Component Styles */

.dropdown-btn {
  border: 1px solid #E4E6EF !important;
}

.multiselect-item-checkbox {
  font-weight: normal;
}

/*
Forms
*/

input.has-error,
select.has-error,
textarea.has-error {
  border-color: red;
  background-color: rgba(255,0,0,0.05);
}
.info-window-marker{
  .card-header{
    min-height: 40px !important;
  }
}
.gm-style-iw-c{
  box-shadow: 0 3px 6px 11px rgba(0,0,0,.3) !important;
}
.gm-ui-hover-effect{
  background: black  !important;
  width: 25px !important;
  height:25px  !important;
  margin: 10px 10px 0px -14px !important;
  border-radius: 50%  !important;
  span{
    width: 20px !important;
    height: 20px !important;
    background: white  !important;
    margin:0 auto !important;
  }
}



/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
