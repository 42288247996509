@import './mixins';

//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: if(isDarkMode(), #D3FCA0, #D3FCA0);
$primary-active: if(isDarkMode(), #F0EDD5, #F0EDD5);
$primary-light: if(isDarkMode(), #4a7e70, #4a7e70);
$primary-super-light: if(isDarkMode(), #b2cdb9, #b2cdb9);
$primary-inverse: white;

$secondary: rgba(10, 52, 0, 0.62);
$secondary-light: rgba(10, 52, 0, 0.7);
$secondary-super-light: rgba(10, 52, 0, 0.4);
$secondary-inverse: white;
$orange: #ED5622;
$pink: #FF708F;
$lima: #D3FCA0;
$lima-20: rgba(211, 252, 160, 0.2);
$lima-90: rgba(211, 252, 160, 0.5);

$sky: #528FB7;
$pink-danger: #FF3D71;
$mango: #FFAA00;


// Success
$success: if(isDarkMode(), #0BB783, #50CD89);
$success-active: if(isDarkMode(), #04AA77, #47BE7D);
$success-light: if(isDarkMode(), #1C3238, #E8FFF3);
$success-inverse: #FFFFFF;

// Info
$info: if(isDarkMode(), $sky, $sky);
$info-active: if(isDarkMode(), #DD95FF, #DD95FF);
$info-light: if(isDarkMode(), #2F264F, #F8F5FF);
$info-inverse: #FFFFFF;

// Danger
$danger: if(isDarkMode(), #F64E60, #FF453A);
$danger-active: if(isDarkMode(), #EE2D41, #D9214E);
$danger-light: if(isDarkMode(), #3A2434, #FFF5F8);
$danger-inverse: #FFFFFF;

// Warning
$warning: if(isDarkMode(), #FFA800, #FFC700);
$warning-active: if(isDarkMode(), #EE9D01, #F1BC00);
$warning-light: if(isDarkMode(), #392F28, #FFF8DD);
$warning-inverse: #FFFFFF;

// Card Box Shadow
$card-box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);

.btn-danger {
  &:hover {
    background-color: transparent !important;
    color: $secondary !important;
  }
}

.btn-remove {
  background-color: #EE2D41 !important;
  color: white !important;
}

.btn-cancel {
  background-color: if(isDarkMode(), $lima-90, $lima-90);
  color: $secondary;
}

button {
  border-radius: 22px;
}