//
// Badge
//

.badge {
    display: inline-flex;
    align-items: center;
    
    // Fixed size
    &.badge-circle,
    &.badge-square {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: $badge-size;
        min-width: $badge-size;
        padding: 0 0.1rem;
        line-height: 0;
    }

    // Circle
    &.badge-circle {
        border-radius: 50%;
        padding: 0;
        min-width: unset;
        width: $badge-size;
    }

    // Sizes
    &.badge-sm {
        min-width: $badge-size-sm;
        font-size: $badge-font-size-sm;

        &.badge-square {
            height: $badge-size-sm;
        }

        &.badge-circle {
            width: $badge-size-sm;
            height: $badge-size-sm;
        }
    }

    &.badge-lg {
        min-width: $badge-size-lg;
        font-size: $badge-font-size-lg;

        &.badge-square {
            height: $badge-size-lg;
        }

        &.badge-circle {
            width: $badge-size-lg;
            height: $badge-size-lg;
        }
    }
}
.badge-light-danger{
    color:#FF453A !important;
    background-color:#FFF2F1 !important;
}
.badge-light-success {
    color: #50CD89 !important;
    background-color: #EEFBEC;
}.badge-light-warning {
    color: #E78B2F !important;
}
.badge-warning {
    color: white !important;

}
.badge-light-purple{
    color: #7239EA !important;;
    background-color: #F8F5FF !important;
}
@each $name, $value in $theme-colors {
    .badge-#{$name} {
        color: theme-inverse-color($name);
        background-color: $value;
    }

    .badge-light-#{$name} {
        color: theme-inverse-color($name);
        background-color: theme-light-color($name);
    }
}
