//
// Menu
//

// Aside menu
.aside-menu {
    .hover-scroll-overlay-y {
        --scrollbar-space: 0.4rem;
        @include scrollbar-color(#3b3b64, lighten(#3b3b64, 3%));
    }

    // Basic
    .menu {
        // Link padding x
        @include menu-link-padding-x(get($aside-config, padding-x));

        // Menu indention
        @include menu-link-indention(get($aside-config, menu-indention), get($aside-config, padding-x));

        .menu-section {
            color: #4c4e6f !important;
        }

        > .menu-item {
          margin-bottom: 12px;
          font-size: 16px;

			> .menu-link {
				width: 90%;
                height: auto;
                margin: 0 auto; 
                flex-direction: row;
                justify-content: flex-start;
                align-items: normal;
                display: flex;     
                @include border-radius($border-radius);     

				.menu-icon {					
					justify-content: center;
                    width: 0;
                    padding-right: 1rem;
                    
                    i {                        
                        font-size: 22px !important;				
                    }					
				} 

                .menu-title {					
					flex-grow: 0;
                    padding-left: 10px;
				}   
			}             

            //$title-color, $icon-color, $bullet-color, $arrow-color, $bg-color, $all-links
            @include menu-link-default-state( $primary-active, $primary-active, $primary-active, $primary-active, null, false);
            @include menu-link-hover-state( $primary, $primary, $primary, $primary, $primary-active, false);
            @include menu-link-here-state( $primary, $primary, $primary, $primary, $primary-active, false);
            @include menu-link-show-state( $primary, $primary, $primary, $primary, $primary-active, false);
            @include menu-link-active-state( $primary, $primary, $primary, $primary, $primary-active , false);
		}
    }     
}