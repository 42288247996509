//
// Custom utilities
//

$utilities: map-merge(
    $utilities,
    (
        "position": (
            property: position,
            responsive: true,
            values: static relative absolute fixed sticky
        ),
        "opacity": (
            property: opacity,
            class: opacity,
            values: $opacity-values
        ),
        "opacity-hover": (
            property: opacity,
            state: hover,
            class: opacity,
            values: $opacity-values
        ),
        "font-size": (
            rfs: true,
            responsive: true,
            property: font-size,
            class: fs,
            values: $font-sizes
        ),
        "width": (
            responsive: true,
            property: width,
            class: w,
            values: $custom-sizes
        ),
        "max-width": (
            responsive: true,
            property: max-width,
            class: mw,
            values: $custom-sizes
        ),
        "min-width": (
            responsive: true,
            property: min-width,
            class: min-w,
            values: $custom-sizes
        ),
        "height": (
            responsive: true,
            property: height,
            class: h,
            values: $custom-sizes
        ),
        "max-height": (
            responsive: true,
            property: max-height,
            class: mh,
            values: $custom-sizes
        ),
        "min-height": (
            responsive: true,
            property: min-height,
            class: min-h,
            values: $custom-sizes
        ),
        "z-index": (
            property: z-index,
            class: z-index,
            values: $zindex-values
        ),
        "border-top-width": (
            property: border-top-width,
            class: border-top,
            values: $border-widths
        ),
        "border-bottom-width": (
            property: border-bottom-width,
            class: border-bottom,
            values: $border-widths
        ),
        "border-right-width": (
            property: border-right-width,
            class: border-right,
            values: $border-widths
        ),
        "border-left-width": (
            property: border-left-width,
            class: border-left,
            values: $border-widths
        ),
        "line-height": (
            property: line-height,
            class: lh,
            values: (
                0: 0,
                1: 1,
                sm: $line-height-sm,
                base: $line-height-base,
                lg: $line-height-lg,
                xl: $line-height-xl,
                xxl: $line-height-xxl
            )
        ),
        "letter-spacing": (
            property: letter-spacing,
            class: ls,
            values: $letter-spacing-values
        ),
        "rounded": (
            property: border-radius,
            class: rounded,
            values: (
                null: $border-radius,
                0: 0,
                1: $border-radius-sm,
                2: $border-radius,
                3: $border-radius-lg,
                4: $border-radius-xl,
                circle: 50%,
                pill: $border-radius-pill
            )
        )
    )
);
.custom-card{
        box-shadow: none !important;
        border:2px solid #EFF0F6 !important;
}